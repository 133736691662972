
@import "../../../assets/styles/variables.scss";

.adminSmallCont {
  max-width: 694px;
  width: 100%;
  margin: 0 auto;
}

.cancelDiv {
  display: flex;
  justify-content: space-between;

  button {
    background-color: $white;
    color: black;
  }
}

.addhappinessBlock {
  margin-top: 10px;
}

.pageTitle {
  font-size: 26px;
  line-height: 43px;
  font-weight: 700;
  margin-bottom: 10px;
}

.happinessImage {
  position: relative;
}

.browseButton {
  position: absolute;
  width: 195px;
  height: 33px;
  background: $black;
  color: $white;
  text-decoration: none;
  font-size: 14px;
  line-height: 23px;
  bottom: 16px;
  left: 50%;
  margin-left: -97px;
  text-align: center;
  padding: 0 0 2px 0;
  outline: none;
  border: unset;
  border-radius: unset;
}

.happinessForm {
  display: flex;
  flex-direction: column;
  margin-top: 27px;
}

.formField {
  margin-bottom: 13px;
}

.formLable {
  font-size: 14px;
  line-height: 23px;
  color: $grey_color_2;
  display: flex;
  justify-content: flex-start;
  margin-bottom: 3px;
}

.inputText {
  background: $white_1;
  border: none;
  height: 40px;
  font-size: 16px;
  color: $black;
  width: 695px !important;
  padding: 5px 10px;
}

.inputTextArea {
  background: $white_1;
  border: none;
  resize: none;
  height: 100px;
  font-size: 16px;
  color: $black;
  padding: 10px;
}

.formField select {
  background: $white_1;
  border: none;
  height: 40px;
  font-size: 16px;
  color: $black;
}

.cf:before,
.cf:after {
  clear: both;
  display: table;
}

.btnContainer {
  display: flex;
  justify-content: space-between;
  margin-top: 17px;
}

.formButtons {
  font-size: 16px;
  font-weight: 500;
  line-height: 26px;
  border-radius: 4px;
  cursor: pointer;
}

.cancelBtn {
  background-color: $dark_grey_2;
  border: 1px solid $dark_grey_2;
  padding: 7px 50px;
  color: $white;

  &:hover {
    background: $white;
    color: $dark_grey_2;
  }
}

.saveBtn {
  height: 40px;
  width: 260px;
  background-color: $yellow_orange;
  padding: 7px 65px;
  color: $white;
}

.close {
  height: 15px;
  width: 15px;
}

.right {
  text-align: right !important;
}
