@charset "utf-8";
/* ************* Media Queries Tablets ****************** */

@media screen and ( max-width: 1200px) {}

@media screen and ( max-width: 1140px) {}

@media screen and ( min-width: 1024px) {}

@media screen and ( max-width: 1023px) {}

@media screen and ( max-width: 992px) {}

@media screen and ( max-width:850px) {}

@media only screen and (max-width: 768px) {
    .loginContainer.ucCont {
        max-width: 390px
    }
    .ucBlock {
        padding: 25px;
    }
    .ucText h4 {
        font-size: 40px;
        line-height: 60px;
        margin: 20px 0 0 0;
    }
}

@media only screen and (max-width: 600px) {}

@media only screen and (max-width: 480px) {
    .ucText h4 {
        font-size: 30px;
        line-height: 48px;
        margin: 10px 0 0 0;
    }
}

@media only screen and (max-width: 360px) {}

@media only screen and (max-width: 320px) {}
