@import '../../assets/styles/variables.scss';

.head {
    background: $black;
    padding: 0 23px;
    line-height: 0;
    position: fixed;
    z-index: 5;
    width: 100%
}

.titleText {
    padding: 16px 0;
    float: left;
    max-width: 200px;
    margin: 0;
}

.titleLink {
    display: inline-block;
}

.newNoti {
    position: absolute;
    width: 8px;
    height: 8px;
    top: 19px;
    right: 24px;
    border: 1px solid $black;
    background: $yellow_orange;
    border-radius: 50%;
}

.headerRight {
    float: left;
    width: calc(100% - 200px);
    text-align: right;

    div {
        display: inline-block;
        margin: 0 0 0 -4px
    }
}

.addAction {
    border-right: 1px solid $dark_grey;
    padding: 15px 5px 15px 0;
}

.addActionLink {
    display: inline-block;
    background: $dark_orange;
    padding: 4px 20px;
    font-size: 14px;
    line-height: 23px;
    font-weight: 500;
    color: $white;
    border-radius: 4px;
    text-decoration: none;
    margin: 0 11px 0 0
}

.notification {
    border-right: 1px solid $dark_grey;
    padding: 20px 27px;
    position: relative;
}

.notificationLink {
    display: inline-block;
}

.userDisaply {
    text-align: left;
    padding: 0 30px 0 20px;
    vertical-align: middle;
}

.userName {
    font-size: 12px;
    line-height: 16px;
    font-weight: 500;
    color: $white;
    margin: 0;
}

.logoutLink {
    font-size: 14px;
    line-height: 23px;
    padding: 4px 21px;
    background: $yellow_orange;
    color: $white;
    font-weight: 500;
    display: inline-block;
    text-decoration: none;
    border-radius: 4px;
    margin: 0;
}

.cf:before,
.cf:after {
    clear: both;
    display: table
}