@import '../../assets/styles/variables.scss';

.loaderDiv {
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: transparent;
}

.loader {
    position: fixed;
    border: 6px solid $white_3;
    border-top: 6px solid $yellow_orange;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 2s linear infinite;
    overflow: show;
    margin: auto;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}