.adminSmallCont {
  max-width: 694px;
  width: 100%;
  margin: 0 auto;
}

.addhappinessBlock {
  margin-top: 10px;
}

.pageTitle {
  font-size: 26px;
  line-height: 43px;
  font-weight: 700;
  margin-bottom: 10px;
}

.user {
  height: 247px;
  width: 508px;
}

.FirstRow {
  margin-top: 30px;
  display: flex;
  justify-content: space-around;
}
.secondRow {
  margin-top: 100px;
  display: flex;
  justify-content: space-around;
}

.ChartHead {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.ChartTitle {
  color: #000000;
  font-family: Poppins;
  font-size: 26px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 43px;
}

.dropDown {
  border: none;
  align-items: center;
  display: flex;
  justify-content: flex-end;
  height: 30px;
  width: 106px;
}
.head {
  margin: 100px 0 30px 50px;
  color: #000000;
  font-family: Poppins;
  font-size: 13px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 21px;
}
.tHead {
  height: 17px;
  width: 24px;
  color: #7a7a7a;
  font-family: Poppins;
  font-size: 12px;
  letter-spacing: 0;
  line-height: 19px;
  border-bottom: 1px solid #e0e0e0 !important;
}
.line {
  margin-top: 30px;
  box-sizing: border-box;
  height: 250px;
  width: 1px;
  border: 1px solid #ededed;
}

.legendDiv {
  display: flex;
  align-items: center;
  margin: 15px 15px 10px 40px;
  justify-content: space-between;
}
.legendText {
  color: #000000;
  font-family: Poppins;
  font-size: 10px;
  letter-spacing: 0;
  line-height: 16px;
  margin-right: 50px;
}
.legendBtnNew {
  display: flex;
  margin-right: 5px;
  height: 9px;
  width: 9px;
  background-color: #ff5900;
  border-radius: 50%;
}
.legendBtnOld {
  display: flex;
  margin-right: 5px;
  height: 9px;
  width: 9px;
  background-color: #19d86c;
  border-radius: 50%;
}
.legend {
  display: flex;
  align-items: center;
}
.legendHappiness {
  color: #000000;
  font-family: Poppins;
  font-size: 10px;
  letter-spacing: 0;
  line-height: 16px;
  margin-right: 20px;
}
.tooltip {
  background-color: red;
}
.div1 {
  background: red;
  height: 10px;
  width: 10px;
  border-radius: 50%;
  margin-right: 10px;
}
