@import "../../../../../assets/styles/variables.scss";

.root {
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid $white_2;
  padding: 20px 0px;
}

.notification {
  display: flex;
  flex-direction: column;
  padding: 20px 0px;
}

.container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.name {
  font-size: 18px;
  line-height: 29px;
  text-align: left;
  margin-left: 10px;
}
.dynamic {
  font-size: 18px;
  line-height: 29px;
  text-align: left;
}

.date {
  font-size: 10px;
  line-height: 18px;
  color: $grey_color_1;
  margin-left: 10px;
}

.infobox {
  display: flex;
  justify-content: space-between;
}

.detailContainer {
  display: flex;
  flex-direction: row;
  margin-top: 20px;

  > div {
    margin-right: 50px;
  }

  span {
    font-size: 12px;
    line-height: 19px;
    color: $grey_color_2;
  }

  .info {
    font-size: 12px;
    line-height: 19px;
    color: $dark_grey_1;
  }
  .happiness {
    margin-right: 11%;
    font-size: 12px;
    line-height: 19px;
    color: $dark_grey_1;
  }
}

.btnContainer {
  .reqbtn {
    display: flex;
    height: 30px;
    color: #ffffff;
	font-family: Poppins;
	
    button {
      width: 80px;
      color: #ffffff;
      font-family: Poppins;
      font-size: 14px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 23px;
      border-radius: 2px;
    }
    .enable {
      background-color: #75c63b;
    }
    .disable {
      background-color: #d44040;
    }
    .disabled {
      color: #7a7a7a;
      font-family: Poppins;
      font-size: 14px;
      background-color: #e6e6e6;
    }
  }
}
