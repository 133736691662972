.formField select {
    background: #F2F2F2;
    border: none;
    height: 40px;
    font-size: 16px;
    color: #000;
}

.react-datepicker__input-container {
    width: 100%;
}

@keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
    }
}

div.loader-div {
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: transparent;
}

div.loader {
    position: fixed;
    border: 6px solid #f3f3f3;
    border-top: 6px solid #FF8400;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 2s linear infinite;
    overflow: show;
    margin: auto;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}

input.error {
    background: #FFF1F1;
}

span.error-message {
    color: #d41a31;
    font-size: 10px;
}
