@import "../../assets/styles/variables.scss";

.root {
  padding: 30px 0px 0px 80px;
  display: flex;
  flex-direction: column;
  .line{
    margin: 40px 0px;  
    width: 693px;
    border: 1px solid #e7e7e7;
  }
  .heading {
    color: #000000;
    font-family: Poppins;
    font-size: 26px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 43px;
  }
  .label {
    padding-top: 15px;
    color: #7a7a7a;
    font-family: Poppins;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 23px;
  }
  .select {
    margin-top: 10px;
    border: none;
    height: 40px;
    width: 340px;
    font-size: 16px;
    border-radius: 0px;
    background-color: #f2f2f2;

    -webkit-appearance: none;
    -moz-appearance: none;
    text-indent: 1px;
    text-overflow: '';
    padding: 2px 40px 2px 20px;
    border: none;
    background-image: url('../../assets/images/arrow.svg');
    background-repeat: no-repeat;
    background-position: right center;
    background-position-x: 95%;
    &::-ms-expand {
      display: none;
    }

    .option {
      padding: 5px;
      color: #000000;
      font-family: Poppins;
      font-size: 16px;
      letter-spacing: 0;
      line-height: 26px;
    }
  }
  .Btn {
    margin-top: 50px;
    border-radius: 5px;
    height: 40px;
    width: 100px;
    background-color: #ff8400;
    color: #ffffff;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 26px;
    text-align: center;
  }
  .time{
    margin-top: 10px;
    border: none;
    height: 40px;
    width: 340px;
    font-size: 16px;
    border-radius: 0px;
    background-color: #f2f2f2;
    text-align: center;
    color: #000000;
    font-family: Poppins;
    font-size: 16px;
    letter-spacing: 3px;
    line-height: 26px;
    cursor:pointer;
  }
}
