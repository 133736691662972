.root {
  display: flex;
  flex-direction: column;

  .fields {
    display: flex;
    flex-wrap: wrap;
  }
  .content {
    display: flex;
    justify-content: space-between;
  }
  .link {
    color: #ff5900;
    font-family: Poppins;
    font-size: 12px;
    letter-spacing: 0;
    line-height: 19px;
    margin-bottom: 20px;
  }
  .description {
    display: flex;

    .leftDiv{
      display: flex;
      flex-direction: column;
      span{
        color: #7A7A7A;
        font-family: Poppins;
        font-size: 12px;
        letter-spacing: 0;
        line-height: 19px;
      }
    }

    .details {
      display: flex;
      flex-direction: column;
      margin-left: 20px;
    }
    .FieldHead {
      p {
        color: #2e2e2e;
        font-size: 12px;
        letter-spacing: 0;
        line-height: 19px;
        font-size: 12px;
        font-weight: 500;
      }
      display: flex;
      flex-direction: column;
      font-weight: 400;
      padding-right: 70px;
      margin: 15px 0 0 0;
      color: #2e2e2e;
      font-family: Poppins;
      font-size: 14px;
      letter-spacing: 0;
      line-height: 23px;

      .phoneNumber {
        display: block;
        font-size: 10px;
        line-height: 16px;
      }
    }
    .title {
      color: #000000;
      font-family: Poppins;
      font-size: 18px;
      letter-spacing: 0;
      line-height: 24px;
    }
    .Btn {
      margin-top: 20px;
      height: 30px;
      width: 80px;
      border-radius: 2px;
      background-color: #333333;
      color: #ffffff;
      font-family: Poppins;
      font-size: 14px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 23px;
    }
  }

  .BtnDiv {
    display: flex;
  }
  button {
    height: 30px;
    width: 80px;
    color: #ffffff;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 23px;
    border-radius: 2px;
  }
  .enable {
    background-color: #75c63b;
  }
  .disable {
    background-color: #d44040;
  }
  .disabled {
    color: #7a7a7a;
    font-family: Poppins;
    font-size: 14px;
    background-color: #e6e6e6;
  }
}
