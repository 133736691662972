@import '../../../assets/styles/variables.scss';

.tableHeader {
    padding-right: 30px;
    float: left;
    font-size: 12px;
    line-height: 19px;
    color: $grey_color_2;
    // border-bottom: 1px solid $white_2;
    padding-bottom: 5px;

    &:last-child {
        padding-right: 0;
    }
}

.helpDetails {
    width: 25%;
    text-align: center;
}

.requestTime {
    width: 10%;
    text-align: center;
}

.postedBy {
    width: 95px;
    text-align: center;
}

.helpTo {
    width: 95px;
    text-align: center;
}

.recommendTo {
    width: 105px;
    text-align: center;
}

.reqStatus {
    width: 12%;
    text-align: left;
}
.rating {
    text-align: center;
}
.tableHead{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    border-bottom: 1px solid #E0E0E0;
}

// .cf:before,
// .cf:after {
//     display: flex;
//     justify-content: space-between;
//     clear: both;
//     // display: table;
//     content: " ";

// }
.btn{
    background-color: #FF8400;
    border: none;
    color: $white;
}