@charset "utf-8";
@import '../../../assets/styles/variables.scss';

.loginContainer {
    position: absolute;
    max-width: 620px;
    width: 100%;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    background: $white;
    padding: 60px;
    border-radius: 4px;
    box-shadow: 0 15px 40px 0 $light_yellow_1;
}

.loginTop {
    text-align: center;
}

.header1 {
    margin: 0;
}

.header2 {
    margin: 10px 0 0 0;
    font-size: 22px;
    line-height: 37px;
    color: $black;
    font-weight: 700;
}

.header3 {
    margin: 0;
    color: $yellow_orange;
    font-size: 12px;
    line-height: 19px;
    font-weight: 600;
}

.loginInputs {
    max-width: 340px;
    width: 100%;
    margin: 22px auto 0;
}

.loginButtonDiv {
    margin-top: 10px;
    width: 100%;
    display: inline-block;
}

.loginBtn {
    height: 40px;
    width: 100%;
    font-size: 16px;
    line-height: 26px;
    font-weight: 500;
    cursor: pointer;
    color: #FFFFFF;
    font-family: Poppins;
    border-radius: 4px;
    background-color: #FF8400;
}

.formField {
    margin-bottom: 10px;
}

.formLable {
    font-size: 14px;
    line-height: 23px;
    color: $grey_color_2;
    display: block;
    margin-bottom: 3px;
}

.inputText {
    background: $white_1;
    padding: 5px 10px;
    border: none;
    height: 40px;
    font-size: 16px;
    color: $black;
}