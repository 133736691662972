@import '../../../assets/styles/variables.scss';

.adminSmallCont {
    max-width: 694px;
    width: 100%;
    margin: 0 auto;
}

.addhappinessBlock {
    margin-top: 10px;
}

.addMultipleHappinessBlock {
    margin-top: 10px;
}

.happinessSeperation {
    height: 1px;
    background-color: $grey_color_3;
    margin: 50px 0;
}

.multipleHappinessUploadModal {
    height: 464px;
    width: 613px;
    background-color: $white;
    position: relative;

    .closeBtnHeader {
        position: absolute;
        top: -2px;
        right: -20px;
    }

    .close {
        width: 22px;
    }

    .progressBarLoader {
        position: relative;
        width: 150px;
        margin: 43px auto 30px auto;

        .loadingImage {
            position: absolute;
            top: 51px;
            left: 51px;
        }
    }

    .modalButtonDiv {
        margin-top: 50px;
    }

    h3.infoText {
        float: none;
        text-align: left;
    }

    .loadingText {
        font-size: 14px;
        line-height: 23px;
    }

    .progressBar {
        width: 150px;

        path:nth-child(2) {
            stroke: $yellow_orange;
            stroke-linecap: unset;
        }
    }
}

.pageTitle {
    font-size: 26px;
    line-height: 43px;
    font-weight: 700;
    margin-bottom: 10px;
}

.happinessImage {
    position: relative;
}

.multipleHappinessFile {
    position: relative;
}

.multipleDiv {
    padding-top: 29px;
    box-sizing: border-box;
    height: 176px;
    text-align: center;
    width: 693px;
    border: 2px dashed $white_2;
    background-color: $white_4;

    h4 {
        margin-top: 5px;
        font-size: 12px;
        line-height: 19px;
    }
}

.browseButton {
    position: absolute;
    width: 195px;
    height: 33px;
    background: $black;
    color: $white;
    text-decoration: none;
    font-size: 14px;
    line-height: 23px;
    bottom: 16px;
    left: 50%;
    margin-left: -97px;
    text-align: center;
    padding: 0 0 2px 0;
    outline: none;
    border: unset;
    border-radius: 2px;
}

.multiplHappinessBrowseButton {
    margin-top: 15px;
    background: $black;
    color: $white;
    width: 195px;
    height: 33px;
    font-size: 14px;
    line-height: 23px;
    text-align: center;
    padding: 0 0 2px 0;
    outline: none;
    border: unset;
    border-radius: 2px;
}

.happinessForm {
    margin-top: 27px;
}

.formField {
    margin-bottom: 13px;
}

.formLable {
    font-size: 14px;
    line-height: 23px;
    color: $grey_color_2;
    display: block;
    margin-bottom: 3px;
}

.inputText {
    background: $white_1;
    border: none;
    height: 40px;
    font-size: 16px;
    color: $black;
    width: 100%;
    padding: 5px 10px;
}


.cols6 {
    float: left;
    width: 50%;

    &:nth-child(odd) {
        padding-right: 7px;
    }

    &:nth-child(even) {
        padding-left: 7px;
    }
}

.cf:before,
.cf:after {
    clear: both;
    display: table
}

.btnContainer {
    margin-top: 17px;
}

.formButtons {
    font-size: 16px;
    font-weight: 500;
    line-height: 26px;
    border-radius: 4px;
    cursor: pointer;
}

.cancelBtn {
    background-color: $dark_grey_2;
    border: 1px solid $dark_grey_2;
    padding: 7px 50px;
    color: $white;

    &:hover {
        background: $white;
        color: $dark_grey_2;
    }
}

.saveBtn {
    background-color: $yellow_orange;
    padding: 7px 65px;
    color: $white;

    &:hover {
        background: $white;
        color: $yellow_orange;
    }
}

.right {
    text-align: right !important;
}