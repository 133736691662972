@charset "utf-8";

@import '../../assets/styles/variables.scss';

.loginContainer {
    position: absolute;
    max-width: 620px;
    width: 100%;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    background: $white;
    padding: 60px;
    border-radius: 4px;
    box-shadow: 0 15px 40px 0 $light_yellow_1;
}

.loginTop {
    text-align: center;
}

.ucCont {
    background: none;
    padding: 0;
    box-shadow: 0 0 0 0 $light_yellow_1;

    @media only screen and (max-width: 768px) {
        max-width: 390px;
    }
}

.ucText {
    text-align: center;
}

.ucBlock {
    background: $white;
    padding: 60px 30px;
    box-shadow: 0 15px 40px 0 $light_yellow_1;

    @media only screen and (max-width: 768px) {
        padding: 25px;
    }
}

.head1 {
    margin-bottom: 20px;
}

.head2 {
    font-size: 20px;
    line-height: 37px;
    font-weight: 700;
    margin-bottom: 52px;
}

.head3 {
    font-size: 16px;
    line-height: 26px;
    font-weight: 400;
    margin-bottom: 20px;
    color: $black
}

.head4 {
    font-size: 50px;
    line-height: 82px;
    font-weight: 300;
    margin: 40px 0 0 0;

    @media only screen and (max-width: 768px) {
        font-size: 40px;
        line-height: 60px;
        margin: 20px 0 0 0;
    }

    @media only screen and (max-width: 480px) {
        font-size: 30px;
        line-height: 48px;
        margin: 10px 0 0 0;
    }

}

.head5 {
    font-size: 16px;
    line-height: 26px;
    font-weight: 400;
    margin: 0;
}

.footerTitle {
    font-size: 16px;
    line-height: 26px;
    font-weight: 500;
    margin: 0;
}

.spanp {
    padding: 0 30px;
    display: inline-block;
}