@import '../../assets/styles/variables.scss';

.sidebar {
    position: fixed;
    left: 0;
    top: 62px;
    height: calc(100% - 62px);
    background: $side_bar_black;
    width: 60px;
    overflow: hidden;
    transition: all 0.5s;
    z-index: 5;

    &:hover {
        width: 250px;

        .menuItemSpan {
            left: 60px;
            transition-delay: 0.318s;
            opacity: 1;
        }
    }
}

.menuItemLink {
    color: $white;
    text-decoration: none;
    padding: 0;
    margin: 0;
    display: block;

    &:hover {
        color: $white;
    }
}

.sbTopLv {
    float: left;
    width: 100%;
}

.menuItemSpan {
    transition: all 0.2s;
    position: absolute;
    left: 60px;
    font-size: 16px;
    line-height: 20px;
    top: 12px;
    opacity: 0;
}

.menuItem {
    float: left;
    width: 100%;
    color: $white;
    position: relative;
    padding: 10px 20px;
    text-align: left;
    transition: all 0.5s;
    margin-top: 15px;

    &:hover {
        cursor: pointer;
        background: $yellow_orange;
    }
}

.active {
    background: $yellow_orange;
}

.menuIcon {
    font-size: 17px;
    font-style: normal;
}

.noList {
    list-style-type: none;
    padding: 0;
    margin: 0;
}