@import '../../assets/styles/variables.scss';

.userHead {
    border-bottom: 1px solid $white_2;
}

.tabRow {
    font-size: 12px;
    line-height: 19px;
    color: $grey_color_2;
    padding-bottom: 5px;
    padding-right: 20px;
    float: left;

    & :last-child {
        padding-right: 0;
    }
}
button{
    background-color: #FF8400;
    border: none;
    color: $white;
}
.usersName {
    width: 24.363636363636362%;
}

.usersEmail {
    width: 19.727272727272727%;
}

.usersMobile {
    width: 14%;
}

.usersGender {
    width: 7.636363636363637%;
}

.usersBdate {
    width: 8.545454545454545%;
}

.usersHf {
    width: 6.0909090909090906%;
}

.usersFriend {
    width: 7.909090909090909%;
}

.usersStatus {
    width: 11.7%;
}

.cf:before,
.cf:after {
    content: " ";
    clear: both;
    display: table
}

