@import "../../../assets/styles/variables.scss";

.root {
  display: flex;
  flex-direction: column;

  a {
    color: #ff5900;
    font-family: Poppins;
    font-size: 12px;
    letter-spacing: 0;
    line-height: 19px;
    margin-bottom: 20px;
  }
}

.separator {
  height: 1px;
  width: 100%;
  color: $white_2;
  margin: 21px auto;
  border: 1px solid;
}

.tabs {
  display: flex;
  flex-direction: row;

  .tab {
    background-color: unset;
    padding-left: unset;
    border: none;
    outline: none;
    font-size: 14px;
    line-height: 23px;
    color: $side_bar_black;
    margin-right: 44px;
    cursor: pointer;

    &.activeTab {
      color: $dark_orange;
      border-bottom: 2px solid $dark_orange;
      border-bottom-left-radius: initial;
      border-bottom-right-radius: initial;
      .count {
        background-color: $dark_orange;
      }
    }
  }

  .count {
    width: 44px;
    height: 22px;
    display: inline-block;
    color: $white;
    background: $side_bar_black;
    border-radius: 12px;
    margin-left: 4px;
  }
}
