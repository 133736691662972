@import '../../../../../assets/styles/variables.scss';

.userHead {
	margin-top: 29px;
}

.tabRow {
	font-size: 12px;
	line-height: 19px;
	color: $grey_color_2;
	padding-bottom: 5px;
	padding-right: 20px;
	float: left;

	& :last-child {
		padding-right: 0;
	}
}
.usersName {
	width: 24.363636363636362%;

	svg {
		width: 34px;
		height: 34px;
		vertical-align: middle;
		margin-right: 10px;
	}
}

.usersEmail {
	width: 19.727272727272727%;
}

.usersMobile {
	width: 14%;
}

.usersPlatform {
	width: 14%;
}

.usersStatus {
	width: 11.7%;
}

.cf:before,
.cf:after {
	content: ' ';
	clear: both;
	display: table;
}

.userContent {
	padding: 16px 0 16px;
	cursor: pointer;
}

.sources {
	display: flex;
	margin-top: 40px;
	> div {
		margin-right: 72px;
		display: flex;
	}
	i {
		font-style: normal;
		padding-right: 10px;
		vertical-align: bottom;
	}

	.active {
		i {
			color: $dark_orange;
		}
	}

	span {
		font-size: 12px;
		line-height: 19px;
	}

	.fb,
	.linkedin,
	.google {
		i,
		span {
			color: $grey_color_2;
		}
	}
}
