@charset "utf-8";
@import '../../assets/styles/variables.scss';

.verifyEmailContainer {
    position: absolute;
    max-width: 620px;
    width: 100%;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    background: $white;
    padding: 60px;
    border-radius: 4px;
    box-shadow: 0 15px 40px 0 $light_yellow_1;
    text-align: center;
}