// Colors
$black: #000000;
$side_bar_black:#333333;

$dark_orange:#FF5900;
$yellow_orange:#FF8400;
$orange_red_1:#FC5A1F;

$dark_grey:#4F4F4F;
$dark_grey_1:#2E2E2E;
$dark_grey_2:#4D4D4D;
$grey_color_1:#868686;
$grey_color_2:#7A7A7A;
$grey_color_3:#E7E7E7;

$white:#fff;
$white_1:#F2F2F2;
$white_2:#E0E0E0;
$white_3:#f3f3f3;
$white_4:#F8F8F8;

$light_yellow_1:#F0DFAA;

$red_1:#d41a31;

// fonts
$verdana: Verdana,
Geneva,
Tahoma,
sans-serif;
$helvetica: Arial,
Helvetica,
sans-serif;
$muli: Muli,
sans-serif;