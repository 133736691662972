@charset "utf-8";
/*======================================================================
Site Name: 90+ Wine
Description: this site is developed by Tech Holding LLC
Version: 1.0
Author: Tech Holding
------------------------------------------------------------------------
Layout Width: 100%
Container Width: 1170px
Responsive Layout: Yes
=======================================================================*/

/********* Reset CSS *********/

*,
*:before,
*:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
font,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td {
  border: 0;
  font-size: 100%;
  font-style: inherit;
  font-weight: inherit;
  margin: 0;
  outline: 0;
  padding: 0;
  vertical-align: baseline;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section,
summary {
  display: block;
}

audio,
canvas,
video {
  display: inline-block;
}

audio:not([controls]) {
  display: none;
  height: 0;
}

html {
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
}

a {
  text-decoration: underline;
  color: #e65a15;
}

a:focus {
  outline: thin dotted;
}

a:hover,
a:active {
  outline: 0;
}

a:hover {
  text-decoration: none;
  color: #393939;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: normal;
}

b,
strong,
dt,
th {
  font-weight: bold;
}

em,
i {
  font-style: italic;
}

p,
h1,
h2,
h3,
h4,
h5,
h6,
ul,
ol,
dd,
table,
fieldset,
address {
  margin: 0;
  padding: 0;
}

figure {
  margin: 0;
}

legend {
  white-space: normal;
}

q {
  quotes: '\201C''\201D''\2018''\2019';
}

small {
  font-size: 80%;
}

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sup {
  top: -0.5em;
}

sub {
  bottom: -0.25em;
}

table {
  border-collapse: separate;
  border-spacing: 0;
  width: 100%;
  empty-cells: show;
  border: none;
}

table table {
  margin: 0;
}

th,
td {
  border: none;
  padding: 8px 10px;
}

caption,
th,
td {
  font-weight: normal;
}

/********* Clear floats *********/

.cf:before,
.cf:after,
.container:before,
.container:after,
.row:before,
.row:after,
ul:before,
ul:after,
.boxs:before,
.boxs:after,
.radioList:after,
.radioList:before {
  content: ' ';
  display: table;
}

.cf:after,
.container:after,
.row:after,
ul:after,
.boxs:after,
.radioList:after {
  clear: both;
}

a {
  outline: none;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

/********* Common Classes *********/

body,
button,
input,
select,
textarea {
  color: #000000;
  font-family: 'Poppins', sans-serif;
}

body {
  background-color: #eee;
}

ul {
  padding: 0 0 0 15px;
}

ol {
  padding: 0 0 0 20px;
}

ul ul,
ol ol {
  margin: 10px 0;
}

ul.bulletText {
  list-style: none;
  padding: 0;
}

/* p,
h1,
h2,
h3,
h4,
h5,
h6,
ul,
ol,
dd,
table,
fieldset,
address {
  margin: 0 0 25px;
} */

input[type='text'],
input[type='email'],
input[type='tel'],
input[type='number'],
input[type='url'],
input[type='password'],
input[type='search'],
select,
textarea {
  /* border: 1px solid #e5e5e5; */
  width: 100%;
  /* height: 30px; */
  /* padding: 5px 10px; */
}

/* input[type='submit'],
input[type='button'],
input[type='reset'],
button {
  background-color: #ff8400;
  color: #fff;
  border: 1px solid #ff8400;
  -webkit-border-radius: 4px;
  border-radius: 4px;
  padding: 7px 10px;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
} */
/* 
input[type='submit']:hover,
input[type='button']:hover,
input[type='reset']:hover,
button:hover,
.button:hover {
  color: #ff8400;
  background-color: #fff;
} */
/* 
input[type='submit'].cancelBtn,
input[type='button'].cancelBtn,
input[type='reset'].cancelBtn {
  background-color: #fff;
} */

input:focus,
button:focus,
textarea {
  outline-style: none;
  box-shadow: none;
}

.displayNone,
.hasJS noscript,
.hideThis {
  display: none !important;
  visibility: hidden;
}

a:active,
a:focus,
a {
  outline: none;
}

/********** Alignment **********/

.left {
  text-align: left !important;
}

.right {
  text-align: right !important;
}

.center {
  text-align: center !important;
}

.justify {
  text-align: justify !important;
}

/* ******** Grid : 12 Coloum and Boxs ******** */

.example div div,
.example .boxs li {
  border: 1px solid #eee;
  margin-bottom: 15px;
  padding-top: 15px;
  padding-bottom: 15px;
}

.row {
  margin: 0;
}

.cols1,
.cols2,
.cols3,
.cols4,
.cols5,
.cols6,
.cols7,
.cols8,
.cols9,
.cols10,
.cols11,
.cols12 {
  float: left;
}

.cols12 {
  width: 100%;
}

.cols11 {
  width: 91.66666667%;
}

.cols10 {
  width: 83.33333333%;
}

.cols9 {
  width: 75%;
}

.cols8 {
  width: 66.66666667%;
}

.cols7 {
  width: 58.33333333%;
}

.cols5 {
  width: 41.66666667%;
}

.cols4 {
  width: 33.33333333%;
}

.cols3 {
  width: 25%;
}

.cols2 {
  width: 16.66666667%;
}

.cols1 {
  width: 8.33%;
}

/********** Login **********/

body.loginBlock {
  background: #fbf2d6;
  padding: 0;
}

.loginContainer {
  position: absolute;
  max-width: 620px;
  width: 100%;
  top: 50%;
  left: 50%;
  -moz-transform: translateX(-50%) translateY(-50%);
  -webkit-transform: translateX(-50%) translateY(-50%);
  -o-transform: translateX(-50%) translateY(-50%);
  -ms-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
  background: #fff;
  padding: 60px;
  -webkit-border-radius: 4px;
  border-radius: 4px;
  -webkit-box-shadow: 0 15px 40px 0 #f0dfaa;
  box-shadow: 0 15px 40px 0 #f0dfaa;
}

.loginContainer .loginTop {
  text-align: center;
}

.loginTop h1 {
  margin: 0;
}

.loginTop h2 {
  margin: 10px 0 0 0;
  font-size: 22px;
  line-height: 37px;
  color: #000;
  font-weight: 700;
}

.loginTop h3 {
  margin: 0;
  color: #ff8400;
  font-size: 12px;
  line-height: 19px;
  font-weight: 600;
}

.loginContainer .loginInputs {
  max-width: 340px;
  width: 100%;
  margin: 22px auto 0;
}

.formField {
  margin-bottom: 10px;
}

.formField label {
  font-size: 14px;
  line-height: 23px;
  color: #7a7a7a;
  display: block;
  margin-bottom: 3px;
}

.formField input {
  background: #f2f2f2;
  border: none;
  height: 40px;
  font-size: 16px;
  color: #000;
}

.formField textarea {
  background: #f2f2f2;
  border: none;
  resize: none;
  height: 100px;
  font-size: 16px;
  color: #000;
  padding: 10px;
}

.loginInputs .loginButton {
  margin-top: 10px;
  width: 100%;
  display: inline-block;
}

.loginInputs .loginButton button {
  width: 100%;
  font-size: 16px;
  line-height: 26px;
  font-weight: 500;
  cursor: pointer;
}

/********** Dashbord **********/

header .headRight {
  float: left;
  width: calc(100% - 200px);
  text-align: right;
}

header .headRight div {
  display: inline-block;
  margin: 0 0 0 -4px;
}

.headRight .addAction {
  border-right: 1px solid #4f4f4f;
  padding: 15px 5px 15px 0;
}

.headRight .addAction a {
  display: inline-block;
  background: #ff5900;
  padding: 4px 20px;
  font-size: 14px;
  line-height: 23px;
  font-weight: 500;
  color: #fff;
  -webkit-border-radius: 4px;
  border-radius: 4px;
  text-decoration: none;
  margin: 0 11px 0 0;
}

.headRight .hf-notification {
  border-right: 1px solid #4f4f4f;
  padding: 20px 27px;
  position: relative;
}

.headRight .hf-notification a {
  display: inline-block;
}

.hf-notification .newNoti {
  position: absolute;
  width: 8px;
  height: 8px;
  top: 19px;
  right: 24px;
  border: 1px solid #000;
  background: #ff8400;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}

.userDisaply {
  text-align: left;
}

.userDisaply {
  padding: 0 30px 0 20px;
  vertical-align: middle;
}

.userDisaply p {
  font-size: 12px;
  line-height: 16px;
  font-weight: 500;
  color: #fff;
  margin: 0;
}

.userDisaply span {
  font-size: 12px;
  line-height: 16px;
  font-weight: 500;
  color: #9b9b9b;
}

.logoutBtn a {
  font-size: 14px;
  line-height: 23px;
  padding: 4px 21px;
  background: #ff8400;
  color: #fff;
  font-weight: 500;
  display: inline-block;
  text-decoration: none;
  -webkit-border-radius: 4px;
  border-radius: 4px;
  margin: 0;
}

/********** Menu **********/

.no-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.no-list li {
  padding: 0;
  margin: 0;
}

.no-list li a {
  padding: 0;
  margin: 0;
  display: block;
}

*:before,
*:after {
  font-family: FontAwesome;
}

aside.sidebar {
  position: fixed;
  left: 0;
  top: 62px;
  height: calc(100% - 62px);
  background: #333333;
  width: 60px;
  overflow: hidden;
  transition: all 0.5s;
  z-index: 99999;
}

aside.sidebar:hover ul.sb_top_lv li span {
  left: 60px;
  transition-delay: 0.318s;
  opacity: 1;
}

aside.sidebar ul.sb_top_lv {
  float: left;
  width: 100%;
}

aside.sidebar ul.sb_top_lv li {
  float: left;
  width: 100%;
  color: white;
  position: relative;
  padding: 10px 20px;
  text-align: left;
  transition: all 0.5s;
  margin-top: 15px;
}

aside.sidebar ul.sb_top_lv li a {
  color: white;
  text-decoration: none;
}

aside.sidebar ul.sb_top_lv li:hover {
  cursor: pointer;
  background: #ff8400;
}

aside.sidebar ul.sb_top_lv li.active {
  background: #ff8400;
}

aside.sidebar ul.sb_top_lv li span {
  transition: all 0.2s;
  position: absolute;
  left: 60px;
  font-size: 16px;
  line-height: 20px;
  top: 12px;
  opacity: 0;
}

aside.sidebar ul.sb_top_lv li i {
  font-size: 17px;
  font-style: normal;
}

.adminContent {
  padding: 82px 20px 50px 82px;
}

.adminSmallCont {
  max-width: 694px;
  width: 100%;
  margin: 0 auto;
}

/********** Add Happiness **********/

.addhappinessBlock {
  margin-top: 10px;
}

h3.pageTitle {
  font-size: 26px;
  line-height: 43px;
  font-weight: 700;
  margin-bottom: 10px;
}

.happinessImage {
  position: relative;
}

.happinessImage a {
  position: absolute;
  width: 195px;
  height: 33px;
  background: #000;
  color: #fff;
  text-decoration: none;
  font-size: 14px;
  line-height: 23px;
  bottom: 16px;
  left: 50%;
  margin-left: -97px;
  text-align: center;
  padding: 4px 0;
}

.happinessForm {
  margin-top: 27px;
}

.happinessForm .formField {
  margin-bottom: 13px;
}

.happinessBtn {
  margin-top: 17px;
}

.happinessBtn .cancelBtn a {
  font-size: 16px;
  font-weight: 500;
  line-height: 26px;
  text-decoration: none;
  background-color: #4d4d4d;
  border: 1px solid #4d4d4d;
  padding: 7px 50px;
  color: #fff;
  -webkit-border-radius: 4px;
  border-radius: 4px;
  display: inline-block;
}

.happinessBtn .cancelBtn a:hover {
  background: #fff;
  color: #4d4d4d;
}

.happinessBtn .saveBtn button {
  font-size: 16px;
  font-weight: 500;
  line-height: 26px;
  background-color: #ff8400;
  padding: 7px 65px;
  color: #fff;
  -webkit-border-radius: 4px;
  border-radius: 4px;
  cursor: pointer;
}

.happinessBtn .saveBtn button:hover {
  background: #fff;
  color: #ff8400;
}

/********** Add User **********/

.ueserAddForm .formField p {
  display: inline-block;
}

.genderRadio [type='radio']:checked,
.genderRadio [type='radio']:not(:checked) {
  position: absolute;
  left: -9999px;
}

.genderRadio [type='radio']:checked + label,
.genderRadio [type='radio']:not(:checked) + label {
  position: relative;
  cursor: pointer;
  line-height: 20px;
  display: inline-block;
  height: 40px;
  line-height: 40px;
  vertical-align: middle;
  width: 100%;
  overflow: hidden;
  color: #000;
  margin: 0;
  text-align: center;
}

.genderRadio [type='radio']:checked + label:after,
.genderRadio [type='radio']:not(:checked) + label:after {
  content: '';
  width: 100%;
  height: 100%;
  background: #000;
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.genderRadio [type='radio']:checked + label {
  color: #fff;
}

.genderRadio [type='radio']:not(:checked) + label:after {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
}

.genderRadio [type='radio']:checked + label:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}

.genderRadio p {
  width: 150px;
  float: left;
  margin: 0;
  border: 1px solid #000;
  -webkit-border-radius: 4px 0 0 4px;
  border-radius: 4px 0 0 4px;
}

.genderRadio p:last-child {
  border-left: 0;
  -webkit-border-radius: 0 4px 4px 0;
  border-radius: 0 4px 4px 0;
}

.sendRequest {
  margin-top: 10px;
  display: inline-block;
}

.sendRequest p {
  margin: 0;
}

.sendRequest input {
  position: absolute;
  opacity: 0;
}

.sendRequest [type='checkbox']:checked,
.sendRequest [type='checkbox']:not(:checked) {
  position: absolute;
  left: -9999px;
}

.sendRequest [type='checkbox']:checked + label,
.sendRequest [type='checkbox']:not(:checked) + label {
  position: relative;
  cursor: pointer;
  padding-left: 35px;
  color: #000;
  font-size: 16px;
  line-height: 26px;
}

.sendRequest [type='checkbox']:checked + label:before,
.sendRequest [type='checkbox']:not(:checked) + label:before {
  content: '';
  width: 20px;
  height: 20px;
  background: #4d4d4d;
  position: absolute;
  z-index: -1;
  top: 3px;
  left: 0;
  border-radius: 2px;
  -webkit-border-radius: 2px;
}

.sendRequest [type='checkbox']:checked + label:after,
.sendRequest [type='checkbox']:not(:checked) + label:after {
  content: '';
  width: 12px;
  height: 7px;
  background: transparent;
  position: absolute;
  z-index: -1;
  border-left: 2px solid #fff;
  border-bottom: 2px solid #fff;
  top: 7px;
  left: 4px;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.sendRequest [type='checkbox']:not(:checked) + label:after {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
}

.sendRequest [type='checkbox']:checked + label:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
  -moz-transform: rotate(-10deg);
  -webkit-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

/********** Notification **********/

.notificationBlock {
  position: relative;
}

.notificationBlock .notifList {
  border: 1px solid #f2f2f2;
  background: #fcfcfc;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  padding: 10px;
  margin-bottom: 10px;
}

.notificationBlock .notifList h4 {
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  margin: 0 0 2px 0;
  color: #000;
}

.notificationBlock .notifList h4 a {
  font-style: normal;
  color: #000;
}

.notificationBlock .notifList span {
  font-size: 12px;
  line-height: 19px;
  color: #7a7a7a;
}

.notifList.unRead {
  border-color: #ff5900;
}

.notifList.unRead h4,
.notifList.unRead h4 a {
  color: #ff5900;
}

.notificationBlock .markReadAll {
  position: absolute;
  right: 0;
  top: 10px;
  font-size: 12px;
  line-height: 19px;
  color: #000;
}

/********** All Requests **********/

.requestsBlock {
  margin-top: 30px;
}

.filters .filRow {
  float: left;
  border-bottom: 0 !important;
  padding-right: 15px;
  border-bottom: 1px solid #e0e0e0;
}

.filterHead .filRow span {
  font-size: 12px;
  line-height: 19px;
  color: #7a7a7a;
  padding-bottom: 4px;
  display: inline-block;
}

.filterHead .filRow input {
  background-color: #f2f2f2;
  border: none;
  height: 40px;
  width: 100%;
  font-size: 14px;
  line-height: 23px;
  color: #000;
}

.filters .filRow:last-child {
  padding-right: 0;
}

.filterHead .search {
  width: 30.54545454545455%;
}

.filterHead .fromTime {
  width: 21.09090909090909%;
}

.filterHead .toTime {
  width: 21.09090909090909%;
}

.filterHead .postBy {
  width: 13.636363636363635%;
}

.filterHead .filStatus {
  width: 13.636363636363635%;
}

.filterHead .searchInput {
  background-image: url('../images/search-icon.svg');
  background-position: 11px center;
  background-repeat: no-repeat;
  background-size: 18px;
  padding-left: 40px;
}

.requestsHead .tabRow {
  font-size: 12px;
  line-height: 19px;
  color: #7a7a7a;
  border-bottom: 1px solid #e0e0e0;
  padding-bottom: 5px;
}

.requestsList .tabRow {
  padding-right: 30px;
  float: left;
}

.requestsList .tabRow:last-child {
  padding-right: 0;
}

.requestsList .helpDetails {
  width: 33.181818181818185%;
}

.requestsList .requestTime {
  width: 15.636363636363637%;
}

.requestsList .postedBy {
  width: 12.090909090909091%;
}

.requestsList .helpTo {
  width: 15%;
}

.requestsList .recommendTo {
  width: 15%;
}

.requestsList .reqStatus {
  width: 9.090909090909091%;
}

.requestsDetail {
  padding: 20px 0 6px;
  font-size: 12px;
  line-height: 19px;
  color: #2e2e2e;
  border-bottom: 1px solid #e0e0e0;
  cursor: pointer;
}

.requestsDetail .tabRow span {
  display: block;
  font-size: 10px;
  line-height: 16px;
}

.requestsDetail .requestsInfo {
  float: left;
}

.requestsDetail .requestsInfo span {
  font-size: 12px;
  line-height: 19px;
  color: #7a7a7a;
}

.requestsDetail .requestsCon {
  padding-left: 145px;
  margin: 0;
  font-size: 14px;
  line-height: 24px;
}

.usersBlock {
  margin-top: 30px;
}

.usersBlock .userHead {
  border-bottom: 1px solid #e0e0e0;
}

.usersBlock .tabRow {
  font-size: 12px;
  line-height: 19px;
  color: #7a7a7a;
  padding-bottom: 5px;
}

.usersList .tabRow {
  padding-right: 20px;
  float: left;
}

.usersList .tabRow:last-child {
  padding-right: 0;
}

.usersList .usersName {
  width: 24.363636363636362%;
}

.usersList .usersEmail {
  width: 19.727272727272727%;
}

.usersList .usersMobile {
  width: 14%;
}

.usersList .usersGender {
  width: 7.636363636363637%;
}

.usersList .usersBdate {
  width: 8.545454545454545%;
}

.usersList .usersHf {
  width: 6.0909090909090906%;
}

.usersList .usersFriend {
  width: 7.909090909090909%;
}

.usersList .usersStatus {
  width: 11.7%;
}

.userContent {
  padding: 16px 0 16px;
  border-bottom: 1px solid #e0e0e0;
  cursor: pointer;
}

.userContent .tabRow {
  font-size: 12px;
  line-height: 19px;
  color: #000;
  padding: 0;
}

.usersList .userImage {
  float: left;
  background: #f2f2f2;
  height: 34px;
  width: 34px;
  line-height: 34px;
  text-align: center;
  border-radius: 50%;
  -webkit-border-radius: 50%;
}

.usersList .userImage i {
  font-style: normal;
  color: #fc5a1f;
  font-size: 19px;
}

.usersList .uesrDtl {
  padding-left: 44px;
  margin: 0;
  font-size: 14px;
  line-height: 23px;
}

.usersList .tabRow span {
  display: block;
  font-size: 10px;
  line-height: 13px;
  color: #868686;
}

.usersList .usersStatus a {
  color: #ff5900;
}

.loginContainer.ucCont {
  background: none;
  padding: 0;
  -webkit-box-shadow: 0 0 0 0 #f0dfaa;
  box-shadow: 0 0 0 0 #f0dfaa;
}

.ucBlock {
  background: #fff;
  padding: 60px 30px;
  -webkit-box-shadow: 0 15px 40px 0 #f0dfaa;
  box-shadow: 0 15px 40px 0 #f0dfaa;
}

.ucBlock h1 {
  margin-bottom: 20px;
}

.ucBlock h2 {
  font-size: 20px;
  line-height: 37px;
  font-weight: 700;
  margin-bottom: 52px;
}

.ucBlock h3 {
  font-size: 16px;
  line-height: 26px;
  font-weight: 400;
  margin-bottom: 20px;
  color: #000;
}

.ucBlock p {
  font-size: 16px;
  line-height: 26px;
  font-weight: 500;
  margin: 0;
}

.ucBlock p span {
  padding: 0 30px;
  display: inline-block;
}

.ucText {
  text-align: center;
}

.ucText h4 {
  font-size: 50px;
  line-height: 82px;
  font-weight: 300;
  margin: 40px 0 0 0;
}

.ucText h5 {
  font-size: 16px;
  line-height: 26px;
  font-weight: 400;
  margin: 0;
}

#chartjs-tooltip {
  opacity: 0;
  position: absolute;
  background: rgba(0, 0, 0, .7);
  color: white;
  padding: 3px;
  border-radius: 3px;
  -webkit-transition: all .1s ease;
  transition: all .1s ease;
  pointer-events: none;
  -webkit-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
}