@import "../../../assets/styles/variables.scss";

.requestsDetail {
  padding: 20px 0 6px;
  font-size: 12px;
  line-height: 19px;
  color: $dark_grey_1;
  border-bottom: 1px solid $white_2;
  cursor: pointer;
}

.tableRow {
  padding-right: 30px;
  float: left;

  &:last-child {
    padding-right: 0;
  }
}

.requestsInfo {
  float: left;
}

.phoneNumber {
  display: block;
  font-size: 10px;
  line-height: 16px;
}

.dateTimeSpan {
  font-size: 12px;
  line-height: 19px;
  color: $grey_color_2;
}

.requestsTitle {
  padding-left: 145px;
  margin: 0;
  font-size: 14px;
  line-height: 24px;
}

.helpDetails {
  width: 40%;
}

.like {
  width: 25%;

  .likeBtn {
    display: flex;
    align-items: center;
    height: 30px;
    border-radius: 50%;
    background-color: #75c63b;
    margin-bottom: 10px;
  }

  .likeIcon {
    width: 16px;
    height: 16px;
  }

  .disLikeBtn {
    display: flex;
    align-items: center;
    height: 30px;
    border-radius: 50%;
    background-color: #D44040;
  }
}

.likeDiv {
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.likeCount {
  margin: 0 0 10px 10px;
}

.disLikeCount {
  margin: 0 0 0 10px;
}

.requestTime {
  width: 21%;
}

.postedBy {
  width: 12.090909090909091%;
}

.helpTo {
  width: 15%;
}

.recommendTo {
  width: 15%;
}

.reqStatus {
  width: 12%;
}

.reqbtnDiv {
  display: flex;
  height: 30px;

  color: #ffffff;
  font-family: Poppins;

  .commonButton {
    background-color: #E6E6E6;
    cursor: pointer;
    color: #7A7A7A;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 23px;
    border-radius: 2px;
  }

  .enable {
    background-color: #75c63b;
  }

  .disable {
    background-color: #d44040;
  }

  .enable,
  .disable {
    cursor: not-allowed;
    color: #ffffff;
  }
}

.cf:before,
.cf:after {
  content: " ";
  clear: both;
  display: table;
}