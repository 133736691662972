@import '../../../../../assets/styles/variables.scss';

.root {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	margin-top: 20px;

	> div {
		margin-right: 20px;
		margin-bottom: 20px;
	}

	img {
		width: 347px;
		height: 150px;
		border-radius: 4px 4px 0 0;
	}

	.textContainer {
		border: 1px solid $white;
		border-radius: 0 0 4px 4px;
		background-color: $white;
	}

	.title {
		max-width: 327px;
		font-size: 16px;
		line-height: 20px;
		padding: 10px 10px 4px 10px;
	}

	.created {
		font-size: 14px;
		line-height: 23px;
		color: $grey_color_2;
		padding-left: 10px;
		padding-right: 24px;
	}
}
