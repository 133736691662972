@import "../../../assets/styles/variables.scss";

.requestsDetail {
  display: flex;
  justify-content: space-between;
  padding: 20px 0 6px;
  font-size: 12px;
  line-height: 19px;
  color: $dark_grey_1;
  border-bottom: 1px solid $white_2;
  cursor: pointer;
}

.tableRow {
  padding-right: 30px;
  float: left;

  &:last-child {
    padding-right: 0;
  }
}

.requestsInfo {
  float: left;
}

.deliver {
  max-width: 130px;
  width: 100%;
}
.completed {
  max-width: 130px;
  width: 100%;
}

.requestsTitle {
  color: #000000;
  font-family: Poppins;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 24px;
  padding-left: 20px;
  margin: 0;
  font-size: 14px;
  line-height: 24px;
}

.helpDetails {
  display: flex;
  max-width: 430px;
  width: 100%;
}
.topNumber {
  max-width: 10px;
  width: 100%;
}

.requestTime {
  max-width: 105px;
  width: 100%;
  color: #2e2e2e;
  font-family: Poppins;
  font-size: 12px;
  letter-spacing: 0;
  line-height: 19px;
}

.like {
  max-width: 100px;
  width: 100%;
  .likeBtn {
    display: flex;
    align-items: center;
    height: 30px;
    border-radius: 50%;
    background-color: #75c63b;
    margin-bottom: 10px;
  }
  .likeIcon {
    width: 16px;
    height: 16px;
  }
  .disLikeBtn {
    display: flex;
    align-items: center;
    height: 30px;
    border-radius: 50%;
    background-color: #d44040;
  }
}

.likeDiv {
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.likeCount {
  margin: 0 0 10px 10px;
}
.disLikeCount {
  margin: 0 0 0 10px;
}
.cf:before,
.cf:after {
  content: " ";
  clear: both;
  display: table;
}
