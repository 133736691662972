@import "../../../assets/styles/variables.scss";

.tableHeader {
  padding-right: 30px;
  float: left;
  font-size: 12px;
  line-height: 19px;
  color: $grey_color_2;
  padding-bottom: 5px;

  &:last-child {
    padding-right: 0;
  }
}

.helpDetails {
  width: 25%;
  text-align: center;
}
.num {
  display: flex;
  align-items: center;
  width: 7%;
  text-align: center;
}

.requestTime {
  width: 10%;
  text-align: center;
}
.hf {
  display: flex;
  align-items: center;
  width: 10%;
  text-align: center;
}
.like {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 10%;
  text-align: center;
}

.tableHead {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  border-bottom: 1px solid #e0e0e0;
}

.btn {
  background-color: #ff8400;
  border: none;
  color: $white;
}
.sortingDiv {
  display: flex;
  flex-direction: column;
}

.upAero {
    margin: 0px 0px 1px 5px;
  }
  .downAero {
    margin: 0px 0px 1px 5px;
  }
  .sortIcon{
    display: flex;
    align-items: center;
  }