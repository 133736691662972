.root {
  display: flex;
  flex-direction: column;

  .content {
    display: flex;
    justify-content: space-between;
  }

  .link {
    color: #ff5900;
    font-family: Poppins;
    font-size: 12px;
    letter-spacing: 0;
    line-height: 19px;
    margin-bottom: 20px;
  }

  .description {
    display: flex;

    .details {
      display: flex;
      flex-direction: column;
      margin-left: 20px;
    }

    .date {
      margin: 15px 0 0 0;
      color: #2E2E2E;
      font-family: Poppins;
      font-size: 14px;
      letter-spacing: 0;
      line-height: 23px;
    }

    .title {
      color: #000000;
      font-family: Poppins;
      font-size: 18px;
      letter-spacing: 0;
      line-height: 24px;
    }

    .Btn {
      margin-top: 20px;
      height: 30px;
      width: 80px;
      border-radius: 2px;
      background-color: #333333;
      color: #FFFFFF;
      font-family: Poppins;
      font-size: 14px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 23px;
    }
  }

  .reqbtnDiv {
    display: flex;
    height: 30px;

    color: #ffffff;
    font-family: Poppins;

    .commonButton {
      background-color: #E6E6E6;
      cursor: pointer;
      color: #7A7A7A;
      font-family: Poppins;
      font-size: 14px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 23px;
      border-radius: 2px;
    }

    .enable {
      background-color: #75c63b;
    }

    .disable {
      background-color: #d44040;
    }

    .enable,
    .disable {
      cursor: not-allowed;
      color: #ffffff;
    }
  }

  .editPopup {
    display: flex;
  }
}