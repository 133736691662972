@import '../../assets/styles/variables.scss';

.tabRow {
    font-size: 12px;
    float: left;
    font-size: 12px;
    line-height: 19px;
    color: $black;
    padding: 0;

    & :last-child {
        padding-right: 0;
    }
}

.dateTime {
    display: block;
    font-size: 10px;
    line-height: 13px;
    color: $grey_color_1;
}

.usersName {
    width: 24.363636363636362%;
}

.usersEmail {
    width: 19.727272727272727%;
}

.usersMobile {
    width: 14%;
}

.usersGender {
    width: 7.636363636363637%;
}

.usersBdate {
    width: 8.545454545454545%;
}

.usersHf {
    width: 6.0909090909090906%;
}

.usersFriend {
    width: 7.909090909090909%;
}

.usersStatus {
    width: 11.7%;
}

.userContent {
    padding: 16px 0 16px;
    border-bottom: 1px solid $white_2;
    cursor: pointer;
}

.userImage {
    float: left;
    background: $white_1;
    height: 34px;
    width: 34px;
    line-height: 34px;
    text-align: center;
    border-radius: 50%;
}

.userIcon {
    font-style: normal;
    color: $orange_red_1;
    font-size: 19px;
}

.uesrDtl {
    padding-left: 44px;
    margin: 0;
    font-size: 14px;
    line-height: 23px;
}

.reqbtnDiv {
    display: flex;
    height: 30px;

    color: #ffffff;
    font-family: Poppins;

    .commonButton {
        background-color: #E6E6E6;
        cursor: pointer;
        color: #7A7A7A;
        font-family: Poppins;
        font-size: 14px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 23px;
        border-radius: 2px;
    }

    .enable {
        background-color: #75c63b;
    }

    .disable {
        background-color: #d44040;
    }

    .enable,
    .disable {
        cursor: not-allowed;
        color: #ffffff;
    }
}

.cf:before,
.cf:after {
    content: " ";
    clear: both;
    display: table
}

.btn {
    background-color: #FF8400;
    border: none;
    color: $white;
}