@import '../../../assets/styles/variables.scss';

.filRow {
    display: flex;
    flex-direction: column;
    float: left;
    border-bottom: 0 !important;
    padding-right: 15px;
    border-bottom: 1px solid $white_2;

    &:last-child {
        padding-right: 0;
    }
}

.select {
    height: 40px;
    width: 140px;
    background-color: #f2f2f2;
    border: none;
    padding-left: 5px;
}

.searchLabel {
    font-size: 12px;
    line-height: 19px;
    color: $grey_color_2;
    padding-bottom: 4px;
    display: inline-block;
}

.inputText {
    background-color: $white_1;
    border: none;
    height: 40px;
    font-size: 14px;
    line-height: 23px;
    color: $black;
    background-color: #f2f2f2;
}

.searchInput {
    background-image: url("../../../assets/images/search-icon.svg");
    background-position: 11px center;
    background-repeat: no-repeat;
    background-size: 18px;
    padding-left: 40px;
}

.search {
    width: 320px;
}

.fromTime {
    width: 21.09090909090909%;
}

.toTime {
    width: 21.09090909090909%;
}

.postBy {
    width: 13.636363636363635%;
}

.filStatus {
    width: 13.636363636363635%;
}

.usersBlock {
    margin-top: 30px;
}

.cf:before,
.cf:after {
    content: " ";
    display: table;
}

.cf:after {
    clear: both;
}